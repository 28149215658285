<template>
  <div class="modal fade" id="poDetailModal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="poDetailModalLabel">
            Vehicle PO Details
          </h4>
          <button type="button" class="close" aria-label="Close" @click="closeModal()"  data-bs-dismiss="modal"">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="card">
                <table class="table custom-table jsmartable">
                    <tr>
                        <td><span class="bold">Vendor ID:</span> {{ showDetail.vendor_id }}</td>
                        <td><span class="bold">PO#:</span> {{ showDetail.excede_po }}</td>
                        <td><span class="bold">Unit#:</span> {{ showDetail.stock }}</td>
                    </tr>
                    <tr>
                        <td><span class="bold">Vendor:</span> {{ showDetail.vendor }}</td>
                        <td><span class="bold">Creation Date:</span> {{ showDetail.entered }}</td>
                        <td><span class="bold">VIN:</span> {{ showDetail.vinNo }}</td>
                    </tr>
                    <tr>
                        <td><span class="bold">Type:</span> {{ showDetail.expense_category }}</td>
                        <td><span class="bold">Status:</span> {{ showDetail.status == 715 ? 'Stocked/Complete' : 'Open'}}</td>
                        <td><span class="bold">Branch:</span> {{ showDetail.branch_name }}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td><span class="bold">Total Invoice:</span> {{ showDetail.actual_cost }}</td>
                        <td></td>
                    </tr>
                    <tr>
                      <td colspan="3">{{ showDetail.summary }}</td>
                    </tr>
                    <tr>
                      <td><span class="bold">TYPE</span></td>
                      <td><span class="bold">DESCRIPTION</span></td>
                      <td><span class="bold">COST</span></td>
                    </tr>
                    <tr>
                      <td>Expense</td>
                      <td>{{ showDetail.summary }}</td>
                      <td>{{ showDetail.actual_cost }}</td>
                    </tr>
                </table>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '../../../../../event-bus';
var poDetail;
export default {
  name: "PoDetailModal",
  data() {
    return {
      showDetail: {},
      modalInstance: null,
    };
  },
  mounted() {
    poDetail = this;
    poDetail.initializeModals();
  },
  methods: {
    initializeModals() {
      poDetail.modalInstance = new bootstrap.Modal(
       document.getElementById("poDetailModal"),
      );
    },
    closeModal() {
      eventBus.$emit("close-details", false, 'po');
    },
    getPoDetail(data) {
      poDetail.modalInstance.show();
      poDetail.showDetail = data;
    }
  },
};

eventBus.$on("show-details", function(value,type) {
  poDetail.getPoDetail(value);
});
</script>